<template>
  <div class="about container">
    <h1>About Ingredient Swap</h1>
    <p>
      Ingredient Swap was created to provide easy access to alternative ingredient suggestions for anyone with an allergy or a dietary preference.
    </p>
    <p>
      The suggestions engine used in this application is that of <a href="https://www.bon-api.com/" target="_blank" rel="noopener">BonAPI</a>'s <a href="https://www.bon-api.com/en/api/documentation/ingredient-alternatives/" target="_blank" rel="noopener">Ingredient Alternatives</a> API.
    </p>
  </div>
</template>
